import { Link } from "react-router-dom";

const Header = () => {
    return (
        <header className="bg-zinc-100 py-8 place-content-center mb-16">
            <Link to="/"><img src="plature_logo.png" className="w-60 mx-auto" alt="logo" /></Link>
        </header>
    )
}

export default Header;