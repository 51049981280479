import { storyblokEditable } from "@storyblok/react";
import { render } from 'storyblok-rich-text-react-renderer';

const Teaser = ({ blok }) => {

    const divBg = { backgroundImage: 'url('+blok.bg_image.filename+')' }

    return (
        <div id="case" className="container mx-auto grid place-content-center h-full px-16 md:px-32 text-center bg-amber-500 bg-cover bg-left-top text-white py-96" style={divBg} {...storyblokEditable(blok)}>
            {render(blok.text)}
        </div>
    );
}

export default Teaser;