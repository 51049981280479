import { storyblokEditable } from "@storyblok/react";
import { render } from 'storyblok-rich-text-react-renderer';

const Case = ({ blok }) => {

    const caseBg = { backgroundImage: 'url('+blok.bg_image.filename+')' }

    if (blok.flip === true) {
        return (
            <div id="case" className="container mx-auto grid grid-cols-1 md:grid-cols-2 gap-16 px-16 md:px-32 py-24 bg-center bg-cover" style={caseBg} {...storyblokEditable(blok)}>
                <div className="grid place-content-center">
                    <img src={blok.image.filename} alt={blok.image.alt} className="w-64" />
                </div>
                <div>
                    {render(blok.text)}
                    <a href={blok.link.cached_url} className="block border border-amber-500 p-2 rounded-xl w-64 text-center my-16">{blok.button_text}</a>
                </div>
                
            </div>
        );
    } else {
        return (
            <div id="case" className={"container mx-auto grid grid-cols-1 md:grid-cols-2 gap-16 px-16 md:px-32 py-24 bg-center bg-cover text-"+blok.text_color} style={caseBg} {...storyblokEditable(blok)}>
                <div>
                    {render(blok.text)}
                    <a href={blok.link.cached_url} className="block border border-amber-500 p-2 rounded-xl w-64 text-center my-16">{blok.button_text}</a>
                </div>
                <div className="grid place-content-center">
                    <img src={blok.image.filename} alt={blok.image.alt} className="w-64" />
                </div>
            </div>
        )
    }

    
}

export default Case;