import { Link } from "react-router-dom";

const Footer = () => {
    return (
    <>
    <footer className="container mx-auto bg-sky-900 mt-16 p-16 md:p-32 grid grid-cols-1 md:grid-cols-3 text-white">
        <div>
            <span className="block text-amber-500 text-2xl py-4">Links</span>
            <ul>
                <li className="my-2"><a href="https://jaeger-app.dk" target="_blank" rel="noreferrer">Jäger</a></li>
            </ul>
        </div>
        <div>
            <span className="block text-amber-500 text-2xl py-4">Information</span>
            <p>Plature ApS</p>
            <p className="my-4">
                CVR: 38732420
              </p>
              <p className="my-4">
                <Link to="/cookie-og-privatlivspolitik">Cookie- og privatlivspolitik</Link>
            </p>
        </div>
        <div>
            <span className="block text-amber-500 text-2xl py-4">Kontakt os</span>
            <p className="text-xl"><a href="tel:004525171800">+45 2517 1800</a></p>
            <p className="text-xl my-2"><a href="mailto:info@plature.com">info@plature.com</a></p>
        </div>
    </footer>
    <div className="container mx-auto py-4 px-4 md:px-0">
        <a href="https://digitised.dk" target="_blank" rel="noreferrer">
            <img src="digitised.png" alt="Udviklet af digitised" className="w-12 grayscale hover:grayscale-0" />
        </a>
    </div>
    </>
    )
}

export default Footer;