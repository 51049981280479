import React from 'react';
import ReactDOM from "react-dom/client";
import {
  BrowserRouter,
  Routes,
  Route,
} from "react-router-dom";
import './index.css';
import App from './App';

// STORYBLOK
import { storyblokInit, apiPlugin } from "@storyblok/react";
import Page from "./components/Page";
import Case from "./components/Case";
import Text from "./components/Text";
import Teaser from "./components/Teaser";

storyblokInit({
  accessToken: "QuE5iVGNq7Sk8IqsVAGe2Att",
  use: [apiPlugin],
  components: {
    page: Page,
    case: Case,
    text: Text,
    teaser: Teaser,
  },
});

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
     <BrowserRouter>
        <Routes>
          <Route path="/" element={<App />} />
          <Route path="*" element={<App />} />
        </Routes>
     </BrowserRouter>
    
  </React.StrictMode>
);