import { StoryblokComponent, storyblokEditable } from "@storyblok/react";
import {Helmet} from "react-helmet";

const Page = ({ blok }) => (
    <>
    <Helmet>
      <title>{ blok.seo.title ? blok.seo.title : "Plature" }</title>
      <meta name="description" content={ blok.seo.description ? blok.seo.description : "" } />
    </Helmet>
    <main {...storyblokEditable(blok)}>
      {blok.body
        ? blok.body.map((blok) => (
            <StoryblokComponent blok={blok} key={blok._uid} />
          ))
        : null}
    </main>
    </>
  );

export default Page;