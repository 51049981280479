import { useStoryblok, StoryblokComponent } from "@storyblok/react";
import Header from "./components/Header"
import Footer from "./components/Footer";

function App() {

  let slug =
  window.location.pathname === "/"
    ? "home"
    : window.location.pathname.replace("/", "");

  const story = useStoryblok(slug, { version: "published" });

  if (!window.location.host.startsWith("www")){
    window.location = window.location.protocol + "//" + "www." + window.location.host + window.location.pathname;
  }

  if (!story || !story.content) {
    return <div className="text-center py-16">Henter...</div>;
  }

  return (
    <>
    
    <div className="font-sans">
      
      <Header />
      <StoryblokComponent blok={story.content} />
      <Footer />
      
    </div>
    </>
  );
}

export default App;
