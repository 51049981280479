import { storyblokEditable } from "@storyblok/react";
import { render } from 'storyblok-rich-text-react-renderer';

const Text = ({ blok }) => {
  return (
    <div id="case" className="container mx-auto px-16" {...storyblokEditable(blok)}>
        {render(blok.text)}
    </div>);
}

export default Text;